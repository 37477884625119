<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "app",
};
</script>

<style>
.mcc_project_con {
  width: 100%;
}
.mcc_project_con .w11402 {
  margin: 0 auto;
  overflow: hidden;
}
.mcc_project_con .w11402 > h2 {
  text-transform: uppercase;
  width: 100%;
  text-align: center;
  border-bottom: 2px solid #ccc;
  line-height: 0.1em;
  margin: 4rem 0 5rem;
}
.mcc_project_con h2 > span {
  background: #fafafa;
  padding: 0 10px;
  box-sizing: border-box;
  /* font-weight: 500; */
}
.mcc_project_con .el-tab-pane h1 {
  font-size: 2.5rem;
  text-align: center;
  font-weight: 500;
}
.mcc_project_con .el-tab-pane > span {
  font-size: 1.7rem;
  color: #5e5e5e;
  display: block;
  margin-top: 3rem;
  margin-bottom: 2rem;
}
.mcc_project_con .el-tab-pane > h2 {
  font-size: 1.8rem;
  margin-top: 1rem;
  line-height: 140%;
}
.mcc_project_con .el-tab-pane > ul {
  margin: 2rem 0;
}
.mcc_project_con .el-tab-pane > ul > li {
  font-size: 1.7rem;
  margin: 1rem 0;
  padding-left: 5rem;
  box-sizing: border-box;
  line-height: 140%;
}
.mcc_project_con .el-tab-pane > p {
  font-size: 1.8rem;
  margin-bottom: 2rem;
  margin-top: 3rem;
}
.mcc_project_con .el-tab-pane > ul > li > span {
  font-weight: 600;
}
.mcc_project_con .el-tab-pane i {
  font-size: 1.8rem;
  margin-right: 1rem;
  /* color: #fb1a1b; */
  color: #ffbb00;
  /* color: #01b5d1; */
  font-weight: 500;
}
</style>
