import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
// 要设置的全局访问的state对象
const state = {
    id: "",
    sKey: '',
    keepAlive: [],
    addObj: {},
    cancelTokenArr: [] //取消请求
}

// 实时监听state值的变化(最新状态)
const getters = {
    // 方法名随意,主要是来承载变化的IdList的值
    getID(state) {
        return state.id
    },
    keepAlive: state => state.keepAlive,
    getAddObj: state => state.addObj,
    pushToken: state => state.cancelTokenArr
}
//和    this.$store.commit("SET_KEEP_ALIVE", ["basePage"]);  这样的提交方式对应
//获取  this.$store.state.ShowProjectNav
const mutations = {
    // 自定义改变state初始值的方法，这里面的参数除了state之外还可以再传额外的参数(变量或对象);
    changeID(state, newVal) {
        state.id = newVal
    },

    SET_KEEP_ALIVE: (state, keepAlive) => {
        state.keepAlive = keepAlive
    },
    SET_ADD_OBJ: (state, addObj) => {
        state.addObj = addObj
    },
    pushToken(state, payload) {
        state.cancelTokenArr.push(payload.cancelToken)
    },
    clearToken({ cancelTokenArr }) {
        cancelTokenArr.forEach(item => {
            item && item()
        })
        cancelTokenArr = []
    }
}


// 和  this.$store.dispatch("updateProjectId", id);  提交方式对应
//获取  this.$store.state.updateNavShow
const actions = {
    updateID(context, newVal) {
        // 自定义触发mutations里函数的方法，context与store 实例具有相同方法和属性
        context.commit('changeID', newVal)
    },
}
const store = new Vuex.Store({
    state,
    getters,
    mutations,
    actions
})
export default store