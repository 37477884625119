/**
 *  Copyright [2022] [https://www.xiaonuo.vip]
 *	Snowy采用APACHE LICENSE 2.0开源协议，您在使用过程中，需要注意以下几点：
 *	1.请不要删除和修改根目录下的LICENSE文件。
 *	2.请不要删除和修改Snowy源码头部的版权声明。
 *	3.本项目代码可免费商业使用，商业使用请保留源码和相关描述文件的项目出处，作者声明等。
 *	4.分发源码时候，请注明软件出处 https://www.xiaonuo.vip
 *	5.不可二次分发开源参与同类竞品，如有想法可联系团队xiaonuobase@qq.com商议合作。
 *	6.若您的项目无法满足以上几点，需要更多功能代码，获取Snowy商业授权许可，请在官网购买授权，地址为 https://www.xiaonuo.vip
 */
import { moduleRequest } from '@/utils/request'

const request = moduleRequest(`/sys/articles/`)
/**
 * 登录
 *
 * @author yubaoshan
 * @date 2022-09-22 22:33:20
 */
export default {
    // 根据类型ID获取新闻列表或者轮播图列表
    GetArticlesByTypeID(data) {
        return request('GetArticlesByTypeID', data, 'get')
    },
    // 根据文章ID获取文章信息
    GetArticlesByID(data) {
        return request('GetArticlesByID', data, 'get')
    },
    // 添加
    add(data) {
        return request('add', data, 'post')
    },
    // 编辑
    edit(data) {
        return request('edit', data, 'post')
    },
    // 删除
    delete(data) {
        return request('delete', data, 'post')
    },
}
