// 封装一个根据屏幕尺寸自动改变 html 的 font-size 大小的函数
const init = function () {
    let clientWidth =
        document.documentElement.clientWidth || document.body.clientWidth;
    const fontSize = (clientWidth / 1920 * 10);
    sessionStorage.setItem('clientWidth', clientWidth)
    document.documentElement.style.fontSize = fontSize + "px";
};

init();

window.addEventListener("resize", init);
export default init;