<template>
  <div class="index_container">
    <div class="index_banner">
      <div class="swiper-container yang" v-if="imgList.length > 0">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="item in imgList" :key="item.id">
            <img :src="Api + item.imgUrl" alt="" />
          </div>
        </div>
        <div class="swiper-pagination"></div>
        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>
      </div>
      <!-- <img src="../assets/img/banner.png" alt="" srcset="" /> -->
      <div class="swiper-container yang" v-else>
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <img src="../assets/img/bannerlogo.jpg" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="index_introduce">
      <div class="w1200">
        <div class="index_introduce_title">
          <span class="title">VIEW FROM THE CHAIR</span>
        </div>
        <div class="index_introduce_imgcon">
          <!-- <div class="index_introduce_img">
          </div> -->
          <img src="../assets/img/img01.jpg" alt="" srcset="" />
          <div class="index_introduce_con">
            <span>
              In 2006 when we applied to the New Zealand government to register
              as a non-profit charitable community trust, we were a group of new
              immigrants from mainland China arriving after 1990’s. We speak
              mandarin.
            </span>
            <span>
              It will soon have been 14 years since the establishment of the
              Manawatu Chinese Community Trust. During these 14 years we have
              conformed to the core objective of promoting non-profit culturally
              significant service to the Chinese community, through the
              promotion of culture and communication, participation in community
              services, community education and research, so as to establish and
              develop each and every activity (service) entry point in
              accordance with the New Zealand government’s and each charitable
              trust fund’s application processes, enabling community volunteers
              to achieve satisfactory performance while actively promoting the
              best results for the community.</span
            >
            <span>
              We cooperate with other Chinese community groups in and out of the
              region, work with other ethnic organizations, local government and
              agencies. Our goal is to open to the whole world to enable people
              from all walks of life to prosper and grow.
            </span>
            <span>
              2006年，当我们向新西兰政府申请注册为非营利慈善社区信托时，我们是一群来自中国大陆的90年代后新移民。我们说普通话。</span
            ><span
              >马那瓦图华人社区信托基金成立14年了。在这14年里，我们一直致力于通过促进文化和交流、参与社区服务、社区教育和研究，为华人社区提供非营利的文化服务，以便根据新西兰政府和各慈善信托基金的申请流程，建立和发展每一项活动（服务）的切入点，使社区志愿者在积极推动社区取得最佳成果的同时，取得令人满意的成绩。</span
            ><span
              >我们与该地区内外的其他华人社区团体合作，与其他民族组织、地方政府和机构合作。我们的目标是向全世界开放，让各行各业的人都能繁荣发展。
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="index_word">
      <img src="../assets/img/guo1.jpg" alt="" />
    </div>
    <div class="index_news">
      <div class="w11402">
        <h2>
          <span class="title">News & Events</span>
        </h2>
        <!--  -->
        <el-skeleton
          style="width: 100%; margin-top: 60px"
          :loading="newsLoading"
          animated
          :count="3"
        >
          <template slot="template">
            <div
              v-if="cWidth > 1024"
              style="
                display: flex;
                justify-content: space-between;
                margin-bottom: 30px;
              "
            >
              <el-skeleton-item
                variant="image"
                style="width: 28vw; height: 32rem"
              />
              <div style="width: calc(100% - 28vw - 15px)">
                <el-skeleton-item variant="h1" style="margin-top: 10px" />
                <!-- <div style="margin-top: 16px">
                  <el-skeleton-item variant="h6" style="width: 30%" />
                </div> -->
                <div style="margin-top: 20px">
                  <el-skeleton-item
                    v-for="(item, index) in 10"
                    :key="index"
                    variant="text"
                    style="margin-right: 16px; margin-bottom: 5px"
                  />
                </div>
              </div>
            </div>
            <div v-else style="margin-bottom: 30px">
              <el-skeleton-item
                variant="image"
                style="width: 100%; height: 60vw"
              />
              <div style="width: 100%">
                <el-skeleton-item variant="h1" style="margin: 20px 0 0 0" />
                <!-- <div style="margin-top: 16px">
                  <el-skeleton-item variant="h6" style="width: 30%" />
                </div> -->
                <div style="margin-top: 15px">
                  <el-skeleton-item
                    v-for="(item, index) in 8"
                    :key="index"
                    variant="text"
                    style="margin-right: 16px; margin-bottom: 5px"
                  />
                </div>
              </div>
            </div>
          </template>

          <template>
            <div
              class="justify_align news_contaner"
              v-for="(item, index) in newsList"
              :key="index"
              @click="tz('News_desc', item.id)"
            >
              <div
                class="index_news_img"
                :style="{
                  background: 'url(' + Api + item.imgUrl + ') no-repeat center',
                }"
              ></div>
              <!-- <img :src="Api + item.imgUrl" alt="" /> -->
              <div class="index_news_info">
                <p>{{ item.title }}</p>
                <!-- <a>{{ item.time || "-" }}</a> -->
                <span>{{ item.introduction }}</span>
              </div>
            </div>
          </template>
        </el-skeleton>
      </div>
    </div>
    <div class="index_logos">
      <div class="w1500">
        <div class="index_logos_con">
          <div class="index_logos_title">
            <span class="iconfont icon-jiedianbangzhu"></span>
            <p class="title">SPONSORS</p>
          </div>
          <el-row>
            <el-col :xs="12" :sm="12" :md="12" :lg="6" :xl="6">
              <a href="https://www.pncc.govt.nz/Home" target="_blank">
                <img src="../assets/img/logos/s1-300x179.jpg" />
              </a>
            </el-col>
            <el-col :xs="12" :sm="12" :md="12" :lg="6" :xl="6">
              <a href="https://lionfoundation.nz/" target="_blank">
                <img src="../assets/img/logos/s2-300x107.jpg" />
              </a>
            </el-col>
            <el-col :xs="12" :sm="12" :md="12" :lg="6" :xl="6">
              <a href="https://www.dia.govt.nz/" target="_blank">
                <img src="../assets/img/logos/s3-300x81.jpg" />
              </a>
            </el-col>
            <el-col :xs="12" :sm="12" :md="12" :lg="6" :xl="6">
              <a href="https://tepuharakeke.org.nz/" target="_blank">
                <img src="../assets/img/logos/s4-300x46.jpg" />
              </a>
            </el-col>
            <el-col :xs="12" :sm="12" :md="12" :lg="6" :xl="6">
              <a href="https://creativenz.govt.nz/" target="_blank">
                <img src="../assets/img/logos/s5-300x75.jpg" />
              </a>
            </el-col>
            <el-col :xs="12" :sm="12" :md="12" :lg="6" :xl="6">
              <a href="https://ecct.org.nz/" target="_blank">
                <img src="../assets/img/logos/s6-300x89.jpg" />
              </a>
            </el-col>
            <el-col :xs="12" :sm="12" :md="12" :lg="6" :xl="6">
              <a href="https://www.pubcharitylimited.org.nz/" target="_blank">
                <img src="../assets/img/logos/s7-300x143.jpg" />
              </a>
            </el-col>
            <el-col :xs="12" :sm="12" :md="12" :lg="6" :xl="6">
              <a href="https://mainlandfoundation.co.nz/" target="_blank">
                <img src="../assets/img/logos/s8-300x103.jpg" />
              </a>
            </el-col>
          </el-row>
        </div>
      </div>
      <!-- <div class="bodyCon08">
        <div class="students">
          <div id="four_flash">
            <div class="flashBg">
              <ul class="mobile">
                <li>
                  <img src="../assets/img/s1-300x179.jpg" />
                </li>
                <li>
                  <img src="../assets/img/s2-300x107.png" />
                </li>
                <li>
                  <img src="../assets/img/s3-300x81.png" />
                </li>
                <li>
                  <img src="../assets/img/s4-300x46.jpeg" />
                </li>
                <li>
                  <img src="../assets/img/s5-300x75.jpg" />
                </li>
                <li>
                  <img src="../assets/img/s6-300x89.jpeg" />
                </li>
                <li>
                  <img src="../assets/img/s7-300x143.jpg" />
                </li>
                <li>
                  <img src="../assets/img/s8-300x103.jpg" />
                </li>
              </ul>
            </div>
            <div class="but_left">
              <img @click="leftClick" src="../assets/img/qianxleft.png" />
            </div>
            <div class="but_right">
              <img @click="rightClick" src="../assets/img/qianxr.png" />
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
import sysApi from "@/api/sys/articles/sysApi";

import Swiper from "swiper/bundle";
import "swiper/swiper-bundle.css";
export default {
  data() {
    return {
      cWidth: sessionStorage.getItem("clientWidth"),
      newsLoading: false,
      currentPage: 1, //当前页数
      pageSize: 20, //每页数量
      total: 0, //总条数
      imgList: [],
      newsList: [],
      index: 0,
    };
  },
  created() {
    this.getList(391234533667429);
    this.getList(395358633667429);
  },
  mounted() {},
  watch: {
    imgList() {
      this.$nextTick(() => {
        new Swiper(".yang", {
          pagination: {
            el: ".swiper-pagination",
          },
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
          loop: false,
          autoplay: {
            delay: 5000,
            disableOnInteraction: false,
          },
        });
      });
    },
  },
  methods: {
    // 获取数据
    /**
     * typeid
     * 新闻：395358633667429
     * 轮播图：391234533667429
     */
    async getList(typeid) {
      if (typeid == 391234533667429) {
        this.pageSize = 99;
      } else if (typeid == 395358633667429) {
        this.pageSize = 3;
        this.newsLoading = true;
      }
      const item = {
        Typeid: typeid,
        Title: "",
        Current: this.currentPage,
        Size: this.pageSize,
        SortField: "",
        SortOrder: "",
        SearchKey: "",
      };

      try {
        const resultData = await sysApi.GetArticlesByTypeID(item); //这里token存储，放到请求拦截裂存储
        if (typeid == 391234533667429) {
          this.imgList = resultData.records;
        } else if (typeid == 395358633667429) {
          this.newsList = resultData.records;
          this.newsLoading = false;
        }
        this.total = resultData.total;
      } catch (err) {
        this.$message({
          type: "error",
          message: err,
          offset: 100,
        });
      }
    },
    tz(name, paramsvalue) {
      $(window).scrollTop(0);
      if (paramsvalue) {
        this.$router.push({
          name: name,
          query: { id: paramsvalue },
        });
      } else {
        this.$router.push({
          name: name,
        });
      }
    },
    // leftClick() {
    //   if (this.index == 0) {
    //     $("ul.mobile").prepend($("ul.mobile").html());
    //     $("ul.mobile").css("left", "-1380px");
    //     this.index = 8;
    //   }
    //   this.index--;
    //   $("#four_flash .flashBg ul.mobile")
    //     .stop()
    //     .animate({ left: -this.index * 326 }, 1000);
    // },
    // rightClick() {
    //   this.index++;
    //   var len = $(".flashBg ul.mobile li").length;
    //   if (this.index + 7 > len) {
    //     $("#four_flash .flashBg ul.mobile")
    //       .stop()
    //       .append($("ul.mobile").html());
    //   }
    //   $("#four_flash .flashBg ul.mobile")
    //     .stop()
    //     .animate({ left: -this.index * 326 }, 1000);
    // },
  },
};
</script>

<style>
.index_container {
  width: 100%;
}
.index_banner {
  width: 100%;
  /* height: 625px; */
  background: #3581c6;
}
.swiper-container {
  /* height: 670px; */
  /* height: 40vw; */
  height: calc(100vh - 180px);
  min-height: 40vw;
  max-height: 50vw;
}
.yang .swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}
.swiper-slide > img {
  width: 90%;
  height: 100%;
}
.swiper-button-prev,
.swiper-button-next {
  color: #fff !important;
}
.swiper-button-prev {
  left: 1.3%;
}
.swiper-button-next {
  right: 1.3%;
}
.index_introduce {
  width: 100%;
  /* height: 378px; */
  margin: 7rem 0;
  position: relative;
}
.index_introduce .w1200 {
  /* min-width: 1000px; */
  /* width: calc(100% - 70rem); */
  margin: 0 auto;
  padding-top: 7rem;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
}
.index_introduce_imgcon {
  /* height: 69rem; */
  min-height: 60rem;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
.index_introduce_imgcon > img {
  width: auto;
  max-width: 33vw;
  min-width: 400px;
  height: 100%;
  vertical-align: text-bottom;
}
.index_introduce .w1200 .index_introduce_con {
  /* padding: 0.5rem 0; */
  max-width: 37vw;
  height: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
}
.index_introduce .w1200 .index_introduce_con > span {
  display: block;
  font-size: 1.8rem;
  line-height: 160%;
  text-align: justify;
  /* text-justify: inter-ideograph;
  word-break: break-all; */
  margin-bottom: 1.5rem;
}
.index_introduce .w1200 .index_introduce_con > span:nth-child(4) {
  margin-top: 1.5rem;
}
.index_introduce .w1200 .index_introduce_con > span:nth-child(4),
.index_introduce .w1200 .index_introduce_con > span:nth-child(5),
.index_introduce .w1200 .index_introduce_con > span:nth-child(6) {
  /* text-indent: 3rem;
  line-height: 3rem;
  margin-bottom: 0.6rem; */
  line-height: 170%;
}
.index_introduce .w1200 .index_introduce_con > span:nth-child(6) {
  margin-bottom: 0;
}
.index_introduce_title {
  position: absolute;
  top: -1rem;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
}
.index_introduce .w1200 .index_introduce_title > span {
  display: block;
}
.index_introduce .w1200 .index_introduce_title > span:before,
.index_introduce .w1200 .index_introduce_title > span:after {
  background: url("../assets/img/sprite.png") no-repeat;
  position: absolute;
  width: 25px;
  height: 20px;
  content: "";
  top: 50%;
  transform: translateY(-50%);
}
.index_introduce .w1200 .index_introduce_title > span:before {
  background-position: 0 0;
  left: -35px;
}
.index_introduce .w1200 .index_introduce_title > span:after {
  background-position: -25px 0;
  right: -35px;
}
.index_word {
  width: 100%;
  /* height: 600px; */
  background: #303b68;
  display: flex;
  justify-content: center;
  align-items: center;
}
.index_word img {
  width: 100%;
  height: auto;
}
.index_logos {
  margin-top: 20px;
  width: 100%;
  background: #ecf5fb;
  /* background: #46a0e3; */
}
.index_logos .w1500 {
  width: 90vw;
  margin: 0 auto;
  position: relative;
}
.index_logos_title {
  /* margin-top: 20px;
  font-size: 30px;
  width: 200px;
  height: 50px;
  background: #3581c6;
  color: #ffffff;
  position: absolute;
  top: -70px;
  text-align: center;
  line-height: 50px;
  border-radius: 2px 2px 0 0; */
  /* color: #ffffff; */
  position: absolute;
  top: 2rem;
  left: 1rem;
  text-align: center;
  border-radius: 2px 2px 0 0;
  color: #303b68;
  /* width: 100%;
  background: #3581c6; */
}
.index_logos_title > span {
  font-size: 3rem;
  color: #3581c6;
  margin-right: 1rem;
}
.index_logos_title > p {
  display: inline-block;
  color: #3581c6;
}
.index_logos_con {
  width: 100%;
  /* padding: 2rem 0; */
  padding-top: 8rem;
  padding-bottom: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.index_logos_con .el-row {
  margin-top: 20px;
}
.index_logos_con .el-row > div > a {
  display: block;
  background: #ffffff;
  margin: 1.5rem 3rem;
  padding: 1.5rem 0;
  transition: all 1s ease 0s;
}
.index_logos_con img {
  width: auto;
  max-width: 80%;
  min-height: 56px;
  max-height: 100px;
  height: 8vh;
  display: block;
  margin: 0 auto;
  /* border: 4px solid #343851;
  border-radius: 82px; */
}
.index_logos_con .el-row > div > div:hover {
  -webkit-box-shadow: 0px 0px 5px 10px rgba(0, 0, 0, 1);
  box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.1);
}
</style>