/**
 *  Copyright [2022] [https://www.xiaonuo.vip]
 *	Snowy采用APACHE LICENSE 2.0开源协议，您在使用过程中，需要注意以下几点：
 *	1.请不要删除和修改根目录下的LICENSE文件。
 *	2.请不要删除和修改Snowy源码头部的版权声明。
 *	3.本项目代码可免费商业使用，商业使用请保留源码和相关描述文件的项目出处，作者声明等。
 *	4.分发源码时候，请注明软件出处 https://www.xiaonuo.vip
 *	5.不可二次分发开源参与同类竞品，如有想法可联系团队xiaonuobase@qq.com商议合作。
 *	6.若您的项目无法满足以上几点，需要更多功能代码，获取Snowy商业授权许可，请在官网购买授权，地址为 https://www.xiaonuo.vip
 */
const DEFAULT_CONFIG = {
	// 首页地址
	// DASHBOARD_URL: '/index',

	// 接口地址
	// API_URL: import.meta.env.VITE_API_BASEURL,
	//请求是否走代理
	// VITE_PROXY: import.meta.env.VITE_PROXY,
	//mqtt配置
	// VITE_MQTT: import.meta.env.VITE_MQTT,
	// 请求超时
	TIMEOUT: 15000,
	//token key
	ACCESS_TOEKN_KEY: 'access-token',

	//刷新token key
	REFRESH_TOEKN_KEY: `x-access-token`,

	// TokenName // Authorization
	TOKEN_NAME: 'Authorization',

	// Token前缀，注意最后有个空格，如不需要需设置空字符串 // Bearer
	TOKEN_PREFIX: 'Bearer ',

	// 追加其他头
	HEADERS: {},

	// 请求是否开启缓存
	REQUEST_CACHE: false,

	// 布局 经典：classical，双排菜单：doublerow
	LAYOUT: 'doublerow',

	// 菜单是否折叠
	MENU_COLLAPSE: false,

	// 模块坞
	MODULE_UNFOLD_OPEN: true,

	// 是否开启多标签
	LAYOUT_TAGS_OPEN: true,

	// 是否开启展示面包屑
	BREADCRUMD_OPEN: false,

	// 顶栏是否应用主题色
	TOP_HANDER_THEME_COLOR_OPEN: false,

	// 顶栏主题色通栏
	TOP_HANDER_THEME_COLOR_SPREAD: false,

	// 侧边菜单是否排他展开
	SIDE_UNIQUE_OPEN: true,

	// 语言
	LANG: 'zh-cn',

	// 主题颜色
	COLOR: '#1890FF',

	// 默认整体主题
	THEME: 'dark',

	//整体表单风格
	FORM_STYLE: 'drawer',

	// 成功色
	success: '#52c41a',
	// 警告色
	warning: '#faad14',
	// 错误色
	error: '#f5222f',

	// 系统基础配置，这些是数据库中保存起来的
	SYS_BASE_CONFIG: {
		// 默认logo
		SYS_LOGO: '/img/logo.png',
		// 背景图
		SYS_BACK_IMAGE: '',
		// 系统名称
		SYS_NAME: 'SimpleAdmin',
		// 版本
		SYS_VERSION: '1.0',
		// 版权
		SYS_COPYRIGHT: 'SimpleAdmin ©2022 Created by 少林寺驻北固山办事处大神父王喇嘛',
		// 版权跳转URL
		SYS_COPYRIGHT_URL: 'https://gitee.com/huguodong520',
		// 默认文件存储
		SYS_DEFAULT_FILE_ENGINE: 'LOCAL',
		// 是否开启验证码
		LOGIN_CAPTCHA_OPEN: 'false',
		// 默认重置密码
		SYS_DEFAULT_PASSWORD: '123456'
	}
}

export default DEFAULT_CONFIG
