/**
 *  Copyright [2022] [https://www.xiaonuo.vip]
 *	Snowy采用APACHE LICENSE 2.0开源协议，您在使用过程中，需要注意以下几点：
 *	1.请不要删除和修改根目录下的LICENSE文件。
 *	2.请不要删除和修改Snowy源码头部的版权声明。
 *	3.本项目代码可免费商业使用，商业使用请保留源码和相关描述文件的项目出处，作者声明等。
 *	4.分发源码时候，请注明软件出处 https://www.xiaonuo.vip
 *	5.不可二次分发开源参与同类竞品，如有想法可联系团队xiaonuobase@qq.com商议合作。
 *	6.若您的项目无法满足以上几点，需要更多功能代码，获取Snowy商业授权许可，请在官网购买授权，地址为 https://www.xiaonuo.vip
 */
// 统一的请求发送
import axios from 'axios'
import {
	Message,
	MessageBox
} from 'element-ui';
import sysConfig from '@/config/index'
import tool from '@/utils/tool'
import qs from 'qs'
import router from '../router'


// 以下这些code需要重新登录
const reloadCodes = [401, 1011007, 1011008]
const errorCodeMap = {
	400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
	401: '用户没有权限（令牌、用户名、密码错误）。',
	403: '用户得到授权，但是访问是被禁止的。',
	404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
	406: '请求的格式不可得。',
	410: '请求的资源被永久删除，且不会再得到的。',
	422: '当创建一个对象时，发生一个验证错误。',
	500: '服务器发生错误，请检查服务器。',
	502: '网关错误。',
	503: '服务不可用，服务器暂时过载或维护。',
	504: '网关超时。'
}
// 定义一个重新登录弹出窗的变量
// const loginBack = ref(false)
let isShowErrorBox = false;
// 创建 axios 实例
const service = axios.create({
	baseURL: Glob.baseUrl, // api base_url
	timeout: sysConfig.TIMEOUT // 请求超时时间
})
// token 键定义
const accessTokenKey = sysConfig.ACCESS_TOEKN_KEY
const refreshAccessTokenKey = sysConfig.REFRESH_TOEKN_KEY
// 清除 token
const clearAccessTokens = () => {
	tool.data.remove(accessTokenKey)
	tool.data.remove(refreshAccessTokenKey)
}
// HTTP request 拦截器
service.interceptors.request.use(
	(config) => {
		const token = tool.data.get(accessTokenKey)
		if (token) {
			config.headers[sysConfig.TOKEN_NAME] = sysConfig.TOKEN_PREFIX + token
			// 判断 accessToken 是否过期
			const jwt = decryptJWT(token)
			const exp = getJWTDate(jwt.exp)
			// token 已经过期
			if (new Date() >= exp) {
				// 获取刷新 token
				const refreshAccessToken = tool.data.get(refreshAccessTokenKey)
				// 携带刷新 token
				if (refreshAccessToken) {
					config.headers['X-' + sysConfig.TOKEN_NAME] = sysConfig.TOKEN_PREFIX + refreshAccessToken
				}
			}
		}
		if (!sysConfig.REQUEST_CACHE && config.method === 'get') {
			config.params = config.params || {}
			config.params._ = new Date().getTime()
		}
		Object.assign(config.headers, sysConfig.HEADERS)
		return config
	},
	(error) => {
		return Promise.reject(error)
	}
)

// 保持重新登录Modal的唯一性
// const error = () => {
// 	// loginBack.value = true
// 	isShowErrorBox = true;
// 	MessageBox({
// 		type: 'error',
// 		title: '提示：',
// 		confirmButtonText: '重新登录',
// 		message: '登录已失效， 请重新登录',
// 		showClose: false,
// 		closeOnClickModal: false,
// 		closeOnPressEscape: false,
// 		callback: action => {
// 			// const store = viewTagsStore() //获取viewTagsStore
// 			// console.log(store.viewTags)

// 			// const lastPath = store.lastPath //获取最后一个路由
// 			// //如果有lastPath
// 			// if (lastPath !== '') {
// 			// 	localStorage.setItem('lastVisitPath', lastPath) //记录当前路由
// 			// }
// 			// loginBack.value = false
// 			isShowErrorBox = false;
// 			tool.data.remove(accessTokenKey)
// 			tool.data.remove(refreshAccessTokenKey)
// 			tool.data.remove('USER_INFO')
// 			tool.data.remove('MENU')
// 			tool.data.remove('PERMISSIONS')
// 			// window.location.reload()
// 			router.replace({
// 				path: "/",
// 				// 登录成功后，跳转到之前的页面
// 				query: {
// 					redirect: router.currentRoute.fullPath
// 				}
// 			})

// 		}
// 	})
// }

// HTTP response 拦截器
service.interceptors.response.use(
	(response) => {
		// 配置了blob，不处理直接返回文件流
		if (response.config.responseType === 'blob') {
			if (response.status === 200) {
				return response
			} else {
				Message.warning('文件下载失败或此文件不存在')
				return
			}
		}
		// 检查并存储授权信息
		checkAndStoreAuthentication(response)

		const data = response.data
		const code = data.code
		if (reloadCodes.includes(code)) {
			// if (!loginBack.value) {
			// 	error()
			// }
			// if (!isShowErrorBox) {
			// 	error()
			// }
			return
		}
		if (code !== 200) {
			if (code === 401) {
				clearAccessTokens()
			}
			const customErrorMessage = response.config.customErrorMessage
			const ignoreError = response.config.ignoreError //如果忽略错误就不输出错误消息
			if (!ignoreError) {
				Message.error(customErrorMessage || data.msg)
			}
			return Promise.reject(data)
			// 自定义错误提示，覆盖后端返回的message
			// 使用示例：
			// export function customerList (data) {
			//   return request('list', data, 'get', {
			//     customErrorMessage: '自定义错误消息提示'
			//   });
			// }
		} else {
			// 统一成功提示
			const responseUrl = response.config.url
			const apiNameArray = [
				'add',
				'edit',
				'delete',
				'update',
				'grant',
				'reset',
				'start',
				'stop',
				'pass',
				'disable',
				'enable',
				'revoke',
				'suspend',
				'active',
				'turn',
				'adjust',
				'reject',
				'copy'
			]
			//指定方法不提示
			const noMessageApiNameArray = []
			apiNameArray.forEach((apiName) => {
				let responseApiArray = responseUrl.split('/') //分割
				let method = responseApiArray[responseApiArray.length - 1] //取最后一个
				let result = noMessageApiNameArray.includes(method)
				if (!result && responseUrl.includes(apiName)) {
					Message.success(data.msg)
				}
			})
		}
		return Promise.resolve(data.data)
	},
	(error) => {
		if (error) {
			const status = 503
			const description = errorCodeMap[status]
			Message.error('请求错误！' + description)
			return Promise.reject(status)
		}
	}
)

export const baseRequest = (url, value = {}, method = 'post', options = {}) => {
	if (sysConfig.VITE_PROXY === 'false') url = sysConfig.API_URL + url //判断是否要走代理模式，走了的话发布之后直接nginx代理
	if (method === 'post') {
		return service.post(url, value, options)
	} else if (method === 'get') {
		return service.get(url, { params: value, ...options })
	} else if (method === 'formdata') {
		// form-data表单提交的方式
		return service.post(url, qs.stringify(value), {
			headers: {
				'Content-Type': 'multipart/form-data'
			},
			...options
		})
	} else {
		return service({
			method: method,
			url,
			data: value,
			...options
		})
	}
}

/**
 * 检查并存储授权信息
 * @param res 响应对象
 */
function checkAndStoreAuthentication(res) {
	// 读取响应报文头 token 信息
	var accessToken = res.headers[accessTokenKey]
	var refreshAccessToken = res.headers[refreshAccessTokenKey]

	// 判断是否是无效 token
	if (accessToken === 'invalid_token') {
		clearAccessTokens()
	}
	// 判断是否存在刷新 token，如果存在则存储在本地
	else if (refreshAccessToken && accessToken && accessToken !== 'invalid_token') {
		tool.data.set(accessTokenKey, accessToken)
		tool.data.set(refreshAccessTokenKey, refreshAccessToken)
	}
}

/**
 * 解密 JWT token 的信息
 * @param token jwt token 字符串
 * @returns <any>object
 */
function decryptJWT(token) {
	token = token.replace(/_/g, '/').replace(/-/g, '+')
	var json = decodeURIComponent(escape(window.atob(token.split('.')[1])))
	return JSON.parse(json)
}

/**
 * 将 JWT 时间戳转换成 Date
 * @description 主要针对 `exp`，`iat`，`nbf`
 * @param timestamp 时间戳
 * @returns Date 对象
 */
function getJWTDate(timestamp) {
	return new Date(timestamp * 1000)
}

// 模块内的请求, 会自动加上模块的前缀
export const moduleRequest =
	(moduleUrl) =>
		(url, ...arg) => {
			return baseRequest(moduleUrl + url, ...arg)
		}

export default service
