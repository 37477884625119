import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Index from '../components/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    redirect: '/index',
    children: [
      {
        path: '/index',
        name: 'Index',
        component: Index,
        alias: '/'
      },
      {
        path: '/aboutus',
        name: 'Aboutus',
        component: () => import('../components/AboutUs.vue')
      },
      {
        path: '/news',
        name: 'News',
        component: () => import('../components/News.vue')
      },
      {
        path: '/news_desc',
        name: 'News_desc',
        component: () => import('../components/news/News_desc.vue')
      },
      {
        path: '/mcc',
        name: 'Mcc',
        component: () => import('../components/Mcc.vue')
      },
      {
        path: '/mcc_desc',
        name: 'Mcc_desc',
        component: () => import('../components/mcc/Mcc_desc.vue')
      },
      {
        path: '/center',
        name: 'Center',
        component: () => import('../components/projects/Center.vue')
      },
      {
        path: '/culture',
        name: 'Culture',
        component: () => import('../components/projects/Culture.vue')
      },
      {
        path: '/education',
        name: 'Education',
        component: () => import('../components/projects/Education.vue')
      },
      {
        path: '/service',
        name: 'Service',
        component: () => import('../components/projects/Service.vue')
      },
    ]
  }
]

const router = new VueRouter({
  mode: "history",
  routes
})

export default router
