<template>
  <div class="top_bottom">
    <el-container>
      <el-header v-if="cWidth > 1024">
        <div class="top_left_logo" @click="tz('Index')">
          <img src="../assets/img/mcct_long_logo01.jpg" alt="" srcset="" />
        </div>
        <div class="top_right_box">
          <div class="top_right_top">
            <div class="top_right_top_rightYellow">
              <div>
                <img src="../assets/img/d3.png" alt="" />
              </div>
              <div></div>
            </div>
            <div>
              <p
                style="font-size: 1.8rem; font-weight: 600; cursor: pointer"
                @click="tz('Aboutus', 'Contact Us')"
              >
                CONTACT US
              </p>
              <span class="colxian" style="margin-left: 1.5rem"></span>
              <a style="margin-left: 0.5rem" class="contact-linxifangshi-wx">
                <span class="iconfont icon-weixin1"></span>
                <div class="weixin-dropdown">
                  <img src="../assets/img/weixinma.png" alt="" />
                </div>
              </a>
              <!-- <span class="colxian"></span> -->
              <a>
                <span class="iconfont icon-lianshu"></span>
              </a>
              <!-- <span class="colxian"></span> -->
              <a>
                <span class="iconfont icon-instagram-fill-round"></span>
              </a>
              <!-- <span class="colxian"></span> -->
              <a>
                <span class="iconfont icon-youtube-fill"></span>
              </a>
              <div @click="tz('Aboutus', 'Contact Us')">
                <i class="iconfont icon-liwu1"></i>
                <span>Make a donation</span>
              </div>
              <i>
                <img src="../assets/img/search.png" alt="" srcset="" />
              </i>
            </div>
          </div>
          <div class="top_right_bot">
            <div>
              <ul>
                <li style="margin-left: 2rem" @click="tz('Index')">
                  <a>
                    <span class="title">Home Page</span>
                    <span class="reo" style="display: block">首页</span>
                  </a>
                </li>
                <li
                  @mouseenter="one_nav_show = true"
                  @mouseleave="one_nav_show = false"
                >
                  <a>
                    <span class="title">About Us</span>
                    <span class="reo" style="display: block">关于我们</span>
                  </a>
                  <div
                    v-if="one_nav_show"
                    class="one_nav"
                    @mouseenter="one_nav_show = true"
                    @mouseleave="one_nav_show = false"
                  >
                    <ul>
                      <li @click="tz('Aboutus', 'Introduction')">
                        <p>Introductions</p>
                        <p>组织介绍</p>
                        <!-- <a class="iconfont icon-youjiantou3"></a> -->
                      </li>
                      <li @click="tz('Aboutus', 'Trustees')">
                        <p>Trustees</p>
                        <p>理事</p>
                        <!-- <a class="iconfont icon-youjiantou3"></a> -->
                      </li>
                      <li @click="tz('Aboutus', 'Contact Us')">
                        <p>Contact Us</p>
                        <p>联系我们</p>
                        <!-- <a class="iconfont icon-youjiantou3"></a> -->
                      </li>
                    </ul>
                  </div>
                </li>
                <li
                  @mouseenter="two_nav_show = true"
                  @mouseleave="two_nav_show = false"
                >
                  <a>
                    <span class="title">Projects</span>
                    <span class="reo" style="display: block">社区项目</span>
                  </a>
                  <div
                    class="one_nav"
                    v-if="two_nav_show"
                    @mouseenter="two_nav_show = true"
                    @mouseleave="two_nav_show = false"
                  >
                    <ul>
                      <li @click="tz('Culture')">
                        <p>Cultural Publicity and Exchange</p>
                        <p>文化宣传</p>
                        <!-- <a class="iconfont icon-youjiantou3"></a> -->
                      </li>
                      <li @click="tz('Service')">
                        <p>Community Service and Consultancy</p>
                        <p>社区服务</p>
                        <!-- <a class="iconfont icon-youjiantou3"></a> -->
                      </li>
                      <li @click="tz('Education')">
                        <p>Education and Research</p>
                        <p>教育研究</p>
                        <!-- <a class="iconfont icon-youjiantou3"></a> -->
                      </li>
                      <li @click="tz('Center')">
                        <p>Mcct Convention Center</p>
                        <p>华会所</p>
                        <!-- <a class="iconfont icon-youjiantou3"></a> -->
                      </li>
                    </ul>
                  </div>
                </li>
                <li @click="tz('Mcc')">
                  <a>
                    <span class="title">Mcct Convention Center</span>
                    <span class="reo" style="display: block">华会所</span>
                  </a>
                </li>
                <li @click="tz('News')">
                  <a>
                    <span class="title">News &amp; Events</span>
                    <span class="reo" style="display: block">新闻活动</span>
                  </a>
                </li>
              </ul>
            </div>
            <div
              @mouseenter="get_help_show = true"
              @mouseleave="get_help_show = false"
            >
              <a>
                <span class="title">Events</span>
                <span class="reo" style="width: 91px; display: block"
                  >最新活动</span
                >
              </a>
              <!-- <a
                @mouseenter="help_us_show = true"
                @mouseleave="help_us_show = false"
              >
                <span class="title">Help Us</span>
                <span class="reo" style="width: 81px; display: block"
                  >Te Āwhina i a Mātou</span
                >
              </a> -->
              <!-- v-if="get_help_show" -->
            </div>
            <div
              class="bot_nav"
              v-if="get_help_show"
              @mouseenter="get_help_show = true"
              @mouseleave="get_help_show = false"
            >
              <div class="bot_nav_left">
                <ul>
                  <li class="bot_nav_left_item">
                    <span class="iconfont">&#xe606;</span>
                    Children & Youth
                  </li>
                  <li class="bot_nav_left_item">
                    <span class="iconfont">&#xe629;</span>
                    Food, Clothing & Furniture
                  </li>
                </ul>
              </div>
              <div
                class="bot_nav_right"
                @mouseenter="get_help_show = true"
                @mouseleave="get_help_show = false"
              >
                <ul>
                  <li
                    v-for="(item, index) in eventsList"
                    :key="index"
                    @click="tz('News_desc', '', item.id)"
                  >
                    <a>
                      <img :src="Api + item.imgUrl" alt="" />
                    </a>
                    <a>
                      <p class="justify_align">{{ item.title }}</p>
                      <span class="justify_align">{{ item.introduction }}</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <!-- <div
              class="bot_nav help_us_nav"
              v-if="help_us_show"
              @mouseenter="help_us_show = true"
              @mouseleave="help_us_show = false"
            >
              <div class="bot_nav_left">
                <ul>
                  <li class="bot_nav_left_item">
                    <span class="iconfont">&#xe73b;</span>
                    Regular Donations
                  </li>
                  <li class="bot_nav_left_item">
                    <span class="iconfont">&#xe6c7;</span>
                    Gift in Your Will
                  </li>
                </ul>
              </div>
              <div class="bot_nav_right">
                <ul>
                  <li>
                    <a>
                      <img src="../assets/img/06.jpg" alt="" />
                    </a>
                    <p>Appeals & Events</p>
                    <span>1000 Days</span>
                    <span>Christmas Appeal</span>
                    <span>Your Support in Action - Newsletter</span>
                    <span>Red Shield Appeal</span>
                    <span>Our Pacific Emergency Fund</span>
                  </li>
                  <li>
                    <a>
                      <img src="../assets/img/07.jpg" alt="" />
                    </a>
                    <p>Make a Donation</p>
                    <span>How to donate with online banking</span>
                    <span>Information about donating by Cheque</span>
                    <span>TaxGift - Update</span>
                  </li>
                  <li>
                    <a>
                      <img src="../assets/img/08.jpg" alt="" />
                    </a>
                    <p>Corporate Partnerships</p>
                    <span>Business Donations </span>
                    <span>Payroll Giving</span>
                    <span>Business News</span>
                    <span>Our Partners</span>
                    <span>Partner With Us</span>
                  </li>
                  <li>
                    <a>
                      <img src="../assets/img/02.jpg" alt="" />
                    </a>
                    <p>Donate to Family Store</p>
                    <span>Pickup donations request form </span>
                    <span>Volunteer at a Family Store</span>
                  </li>
                </ul>
              </div>
            </div> -->
          </div>
        </div>
      </el-header>
      <el-header class="app_header" v-else>
        <div class="app_top_left_logo" @click="tz('Index')">
          <img src="../assets/img/mcct_long_logo01.jpg" alt="" srcset="" />
        </div>
        <input
          id="toggle"
          type="checkbox"
          v-model="isAppNavShow"
          @change="
            isAppNavShow = true;
            one_nav_show = false;
            two_nav_show = false;
            three_nav_show = false;
            four_nav_show = false;
          "
        />

        <label for="toggle" class="hamburger">
          <div class="top-bun"></div>
          <div class="meat"></div>
          <div class="bottom-bun"></div>
        </label>
        <div class="nav" v-if="isAppNavShow">
          <div class="nav-wrapper">
            <nav>
              <div class="app_nav_right_nav">
                <ul>
                  <li>
                    <a
                      @click="
                        tz('Index');
                        isAppNavShow = false;
                      "
                    >
                      <span class="en_title">Home Page</span>
                      <span class="reo" style="display: block">首页</span>
                    </a>
                  </li>
                  <li>
                    <a @click="one_nav_show = !one_nav_show">
                      <span class="en_title">About Us</span>
                      <span class="reo" style="display: block">关于我们</span>
                      <span
                        class="iconfont icon-xiajiantou2-copy youjt"
                        v-if="one_nav_show"
                      ></span>
                      <span
                        class="iconfont icon-youjiantou2 youjt"
                        v-else
                      ></span>
                    </a>
                    <div class="one_nav" v-if="one_nav_show">
                      <ul>
                        <li
                          @click="
                            tz('Aboutus', 'Introduction');
                            isAppNavShow = false;
                          "
                        >
                          <p>Introductions</p>
                          <p>组织介绍</p>
                          <a class="iconfont icon-youjiantou1"></a>
                        </li>
                        <li
                          @click="
                            tz('Aboutus', 'Trustees');
                            isAppNavShow = false;
                          "
                        >
                          <p>Trustees</p>
                          <p>理事</p>
                          <a class="iconfont icon-youjiantou1"></a>
                        </li>
                        <li
                          @click="
                            tz('Aboutus', 'Contact Us');
                            isAppNavShow = false;
                          "
                        >
                          <p>Contact Us</p>
                          <p>联系我们</p>
                          <a class="iconfont icon-youjiantou1"></a>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <a @click="two_nav_show = !two_nav_show">
                      <span class="en_title">Projects</span>
                      <span class="reo" style="display: block">社区项目</span>
                      <span
                        class="iconfont icon-xiajiantou2-copy youjt"
                        v-if="two_nav_show"
                      ></span>
                      <span
                        class="iconfont icon-youjiantou2 youjt"
                        v-else
                      ></span>
                    </a>
                    <div class="one_nav" v-if="two_nav_show">
                      <ul>
                        <li
                          @click="
                            tz('Culture');
                            isAppNavShow = false;
                          "
                        >
                          <p>Cultural Publicity and Exchange</p>
                          <p>文化宣传</p>
                          <a class="iconfont icon-youjiantou1"></a>
                        </li>
                        <li
                          @click="
                            tz('Service');
                            isAppNavShow = false;
                          "
                        >
                          <p>Community Service and Consultancy</p>
                          <p>社区服务</p>
                          <a class="iconfont icon-youjiantou1"></a>
                        </li>
                        <li
                          @click="
                            tz('Education');
                            isAppNavShow = false;
                          "
                        >
                          <p>Education and Research</p>
                          <p>教育研究</p>
                          <a class="iconfont icon-youjiantou1"></a>
                        </li>
                        <li
                          @click="
                            tz('Center');
                            isAppNavShow = false;
                          "
                        >
                          <p>Mcct Convention Center</p>
                          <p>华会所</p>
                          <a class="iconfont icon-youjiantou1"></a>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <a
                      @click="
                        tz('Mcc');
                        isAppNavShow = false;
                      "
                    >
                      <span class="en_title">Mcct Convention Center</span>
                      <span class="reo" style="display: block">华会所</span>
                    </a>
                  </li>
                  <li
                    @click="
                      tz('News');
                      isAppNavShow = false;
                    "
                  >
                    <a>
                      <span class="en_title">News &amp; Events</span>
                      <span class="reo" style="display: block">新闻活动</span>
                    </a>
                  </li>
                  <li>
                    <a @click="three_nav_show = !three_nav_show">
                      <span class="en_title">Events</span>
                      <span class="reo" style="display: block">最新活动</span>
                      <span
                        class="iconfont icon-xiajiantou2-copy youjt"
                        v-if="three_nav_show"
                      ></span>
                      <span
                        class="iconfont icon-youjiantou2 youjt"
                        v-else
                      ></span>
                    </a>
                    <div class="one_nav three_nav" v-if="three_nav_show">
                      <ul>
                        <li
                          v-for="(item, index) in eventsList"
                          :key="index"
                          @click="
                            tz('News_desc', '', item.id);
                            isAppNavShow = false;
                          "
                        >
                          <p class="justify_align">{{ item.title }}</p>
                        </li>
                        <!-- <li>
                          <p>Children & Youth</p>
                          <a class="iconfont youjt">&#xe606;</a>
                        </li>
                        <li>
                          <p>Food, Clothing & Furniture</p>
                          <a class="iconfont youjt">&#xe629;</a>
                        </li> -->
                      </ul>
                    </div>
                  </li>
                  <li
                    @click="
                      tz('Aboutus', 'Contact Us');
                      isAppNavShow = false;
                    "
                  >
                    <!-- @click="four_nav_show = !four_nav_show" -->
                    <a>
                      <span class="en_title">Contact US</span>
                      <span class="reo" style="display: block">联系我们</span>
                      <!-- <span
                        class="iconfont icon-xiajiantou2-copy youjt"
                        v-if="four_nav_show"
                      ></span>
                      <span
                        class="iconfont icon-youjiantou2 youjt"
                        v-else
                      ></span> -->
                    </a>
                    <!-- <div
                      id="lianxi_nav"
                      class="one_nav three_nav"
                      v-if="four_nav_show"
                    >
                      <ul>
                        <li>
                          <a class="iconfont icon-xianxingdianhua youjt"></a>
                          <p>Chinese Hotline:+64 6 3562629</p>
                        </li>
                        <li>
                          <a class="iconfont icon-shouji youjt"></a>
                          <p>Mobile phone:+64 27 8819818</p>
                        </li>
                        <li>
                          <a class="iconfont icon-iconfontunie62c youjt"></a>
                          <p>Email: mccttrust@gmail.com</p>
                        </li>
                        <li>
                          <a class="iconfont icon-icon_weixin-logo youjt"></a>
                          <p>Wechat:Wen631090</p>
                        </li>
                        <li>
                          <a class="iconfont icon-lianshu youjt"></a>
                          <p>Facebook: Mcct Trust</p>
                        </li>
                        <li>
                          <a class="iconfont icon-yumingyuwangzhan youjt"></a>
                          <p>Website: www.manawatuchinese.org.nz</p>
                        </li>
                      </ul>
                    </div> -->
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </el-header>
      <el-main :style="{ 'margin-top': cWidth > 1024 ? '180px' : '80px' }">
        <div class="btnTop" v-if="btnFlag" @click="backTop()"></div>
        <router-view></router-view>
      </el-main>
      <el-footer>
        <div class="footer_top">
          <div class="footer">
            <div>
              <p>
                <span class="iconfont icon-xianxingdianhua"></span>Chinese
                Hotline:+64 6 3562629
              </p>
              <p>
                <span class="iconfont icon-shouji"></span>Mobile phone:+64 27
                8819818
              </p>
              <p>
                <span class="iconfont icon-iconfontunie62c"></span>Email:
                mccttrust@gmail.com
              </p>
              <p>
                <span class="iconfont icon-icon_weixin-logo"></span
                >Wechat:Wen631090
              </p>
              <p>
                <span class="iconfont icon-lianshu"></span>Facebook: Mcct Trust
              </p>
              <p>
                <span class="iconfont icon-yumingyuwangzhan"></span>Website:
                www.manawatuchinese.org.nz
              </p>
            </div>
            <div>
              <ul>
                <li>
                  <span @click="tz('Index')">Home Page</span>
                  <span @click="tz('Aboutus')">ABOUT US</span>
                  <span @click="tz('Aboutus', 'Contact Us')">CONTACT US</span>
                </li>
                <li>
                  <span @click="tz('Culture')"
                    >Cultural Publicity and Exchange</span
                  >
                </li>
                <li>
                  <span @click="tz('Service')"
                    >Community Service and Consultancy</span
                  >
                </li>
                <li>
                  <span @click="tz('Education')">Education and Research</span>
                  <span @click="tz('Center')">MCCT Center</span>
                </li>
                <li>
                  <span @click="tz('Mcc')">Mcct Convention Center</span>
                  <span @click="tz('News')">News &amp; Events</span>
                </li>
              </ul>
              <div>
                <span class="iconfont icon-weixin1"></span>
                <span class="iconfont icon-lianshu"></span>
                <span class="iconfont icon-instagram-fill-round"></span>
                <span class="iconfont icon-youtube-fill"></span>
              </div>
            </div>
          </div>
        </div>
        <div class="footer_bottom">
          <div class="footer">
            Copyright @ MCCT - Manawatu Chinese Community Trust
          </div>
        </div>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
// import $ from "jquery";
import sysApi from "@/api/sys/articles/sysApi";

export default {
  name: "Home",
  data() {
    return {
      currentPage: 1, //当前页数
      pageSize: 4, //每页数量，最新活动只显示前四个
      eventsList: [],
      isAppNavShow: false,
      cWidth: sessionStorage.getItem("clientWidth"),
      one_nav_show: false,
      two_nav_show: false,
      three_nav_show: false,
      four_nav_show: false,
      get_help_show: false,
      help_us_show: false,
      btnFlag: false,
    };
  },
  created() {
    // 获取最新活动
    this.getList(395358633660911);
  },
  // 利用VUE写一个在控制台打印当前的scrollTop。首先，在 mounted 钩子中给window添加一个滚动滚动监听事件
  mounted() {
    window.addEventListener("scroll", this.scrollToTop, true);
    //如果你需要把这个页面当做子组件引入 需要加true 因为 ····  请看最后一张图片
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollToTop, true);
  },
  methods: {
    // 获取最新活动
    // 获取数据
    /**
     * typeid
     * 新闻：395358633667429
     * 轮播图：391234533667429
     */
    async getList(typeid) {
      const item = {
        Typeid: typeid,
        Title: "",
        Current: this.currentPage,
        Size: this.pageSize,
        SortField: "",
        SortOrder: "",
        SearchKey: "",
      };

      try {
        const resultData = await sysApi.GetArticlesByTypeID(item); //这里token存储，放到请求拦截裂存储
        this.eventsList = resultData.records;
      } catch (err) {
        this.$message({
          type: "error",
          message: err,
          offset: 100,
        });
      }
    },
    tz(name, paramsvalue, id) {
      $(window).scrollTop(0);
      if (paramsvalue) {
        this.$router.push({
          name: name,
          query: { param: paramsvalue },
        });
      } else if (id) {
        this.$router.push({
          name: name,
          query: { id: id },
        });
      } else {
        this.$router.push({
          name: name,
        });
      }
      this.one_nav_show = false;
      this.two_nav_show = false;
      this.get_help_show = false;
    },
    // 点击图片回到顶部方法，加计时器是为了过渡顺滑
    backTop() {
      let that = this;
      let timer = setInterval(() => {
        let ispeed = Math.floor(-that.scrollTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop =
          that.scrollTop + ispeed;
        if (that.scrollTop === 0) {
          clearInterval(timer);
        }
      }, 16);
    },
    // 监听回到顶部按钮距浏览器顶部的距离，滚动的距离如果大于浏览器高度时，设置 btnFlag 为true,否则就是false
    scrollToTop() {
      let that = this;
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      that.scrollTop = scrollTop;
      // console.log(scrollTop);
      if (scrollTop == 0) {
        $(".el-header").css("box-shadow", "none");
      } else {
        $(".el-header").css(
          "box-shadow",
          "0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04)"
        );
      }
      //为了计算距离顶部的高度，当高度大于50显示回顶部图标，小于50则隐藏
      if (that.scrollTop > 50) {
        that.btnFlag = true;
      } else {
        that.btnFlag = false;
      }
    },
  },
};
</script>
<style>
/* 顶部 */
.el-header {
  width: 100vw;
  padding: 3px !important;
  height: 180px !important;
  display: flex;
  background: #fff;
  position: fixed;
  z-index: 9999;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04); */
}
.top_left_logo {
  /* width: 300px; */
  width: 17vw;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.top_left_logo > img {
  /* width: 120px; */
  /* width: 290px; */
  width: 16vw;
  /* height: 150px; */
  height: auto;
}
.colxian {
  display: inline-block;
  width: 1px;
  height: 4rem;
  border-right: 1px solid #fff;
}
.top_right_box {
  width: calc(100% - 18vw - 3px);
  height: 100%;
  margin-left: 3px;
}
.top_right_top {
  width: 100%;
  height: 73px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  /* padding: 0 15px; */
  box-sizing: border-box;
  font-weight: 600;
  color: #ffffff;
}
/* .top_right_top > div:nth-child(1), */
.top_right_top > div:nth-child(2) > div {
  position: relative;
  width: 25rem;
  height: 43px;
  font-size: 1.6rem;
  background: #fff;
  border: solid 1px #ccc;
  box-sizing: border-box;
  color: #333;
}
.top_right_top > div:nth-child(2) {
  width: 49%;
  height: 100%;
  background: #3581c6;
  padding: 0 1.4rem;
  box-sizing: border-box;
}
.top_right_top > img {
  position: absolute;
  transform: translateY(-50%);
  top: 188%;
  left: 0;
  width: 200%;
  height: 450%;
}
.top_right_top > .top_right_top_rightYellow {
  position: relative;
  overflow: hidden;
  width: 51%;
  height: 100%;
  display: flex;
  background: #ffbb00;
  background-image: linear-gradient(
    to right,
    #ffbb00,
    #ffbb00,
    #ffbb00,
    #ffbb00,
    #3581c6
  );
}
.top_right_top > .top_right_top_rightYellow > div {
  width: 50%;
  height: 100%;
  /* background: #3581c6; */
}
.top_right_top > .top_right_top_rightYellow > div:nth-child(1) {
  /* background: #ffbb00;
  background-image: linear-gradient(
    to right,
    #ffbb00,
    #ffbb00,
    #ffbb00,
    #3581c6,
    #3581c6,
    #3581c6
  ); */
}
.top_right_top > .top_right_top_rightYellow img {
  position: absolute;
  transform: translateY(-50%);
  top: 188%;
  left: 0;
  width: 110%;
  height: 450%;
  /* top: 50%;
  left: 1rem;
  width: auto;
  height: 90%; */
}
.top_right_top > div:nth-child(1) span,
.top_right_top > div:nth-child(2) > div > span {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 30px;
}
.top_right_top .address {
  width: 25px;
  position: absolute;
  left: 4px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}
.top_right_top .bot_jt {
  width: 15px;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.top_right_top > div:nth-child(2) {
  position: relative;
  display: flex;
  align-items: center;
}
.top_right_top > div:nth-child(2) > a {
  display: inline-block;
  width: 3rem;
  height: 100%;
  padding: 0 1.1rem;
  /* border-right: solid 1px #ccc; */
  cursor: pointer;
  white-space: nowrap;
  position: relative;
}
.top_right_top > div:nth-child(2) > a > span {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 3rem;
  font-weight: 500;
}
.top_right_top > div:nth-child(2) > a:nth-child(6) {
  border: none;
  /* margin-top: -4px; */
  margin-right: 1.6rem;
}
.top_right_top > div:nth-child(2) > div {
  /* width: 30rem; */
  color: #757575;
  border-radius: 1px;
}
.top_right_top > div:nth-child(2) > div i {
  text-decoration: none;
  font-size: 28px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
}
.top_right_top > div:nth-child(2) > div > span {
  font-size: 1.7rem;
  left: 46px;
  text-transform: uppercase;
}
.top_right_top > div:nth-child(2) > div:hover {
  color: #f9cb4b !important;
  cursor: pointer;
}
.top_right_top > div:nth-child(2) > i {
  display: block;
  width: 44px;
  height: 44px;
  background: #3581c6;
  margin: 0 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
/* .top_right_top > div:nth-child(2) > a:hover {
  background: #f9cb4b;
} */
.top_right_top > div:nth-child(2) > i > img {
  width: 20px;
}

.top_right_bot {
  width: 100%;
  margin-top: 3px;
  height: calc(100% - 76px);
  background: #46a0e3;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  flex-wrap: nowrap;
}
.top_right_bot > div:nth-child(1) {
  width: 100%;
}
.top_right_bot > div:nth-child(1) > ul > li {
  margin-right: 6rem;
  /* max-width: 25rem; */
  height: 101px;
  list-style: none;
  float: left;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-wrap: nowrap;
  position: relative;
}
.top_right_bot > div:nth-child(1) > ul > li:hover {
  color: #f9cb4b;
  cursor: pointer;
}
.top_right_bot > div:nth-child(1) span {
  display: inline-block;
  width: 100%;
  height: 3rem;
  white-space: nowrap;
}
.top_right_bot > div:nth-child(1) .title,
.top_right_bot > div:nth-child(2) > a .title {
  font-size: 2.5rem;
  font-weight: 500;
}
.top_right_bot > div:nth-child(1) .reo,
.top_right_bot > div:nth-child(2) > a .reo {
  font-size: 1.6rem;
  /* font-style: italic; */
  letter-spacing: 0.4rem;
}

.top_right_bot > div:nth-child(2) {
  height: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  /* width: 27vw; */
  width: 22vw;
  border-left: 3px solid #fff;
  background: #3581c6;
  box-sizing: border-box;
  color: #ffffff;
  position: relative;
  cursor: pointer;
}
.top_right_bot > div:nth-child(2) > a {
  /* display: inline-block;
  width: 20vw;
  height: 100%;
  border-left: 3px solid #fff;
  background: #3581c6;
  box-sizing: border-box;
  color: #ffffff;
  position: relative;
  cursor: pointer; */

  /* display: inline-block;
  width: 141px;
  height: 100%;
  border-left: 3px solid #fff;
  background: #3581c6;
  box-sizing: border-box;
  color: #ffffff;
  position: relative;
  cursor: pointer; */
}
.top_right_bot > div:nth-child(2) > a > span {
  display: inline-block;
  width: 100%;
  height: 3rem;
  text-align: center;
  /* position: absolute;
  left: 50%;
  transform: translateX(-50%); */
  white-space: nowrap;
}
.top_right_bot > div:nth-child(2) > a .title {
  /* font-size: 2.3rem; */
  font-weight: 600;
  /* top: 25px; */
}
.top_right_bot > div:nth-child(2) > a .reo {
  /* top: 52px; */
  /* font-size: 1.5rem; */
  /* font-style: italic; */
  /* letter-spacing: 0.3rem; */
}
.top_right_bot > div:nth-child(2):hover {
  color: #f9cb4b;
}
.one_nav {
  /* width: 170px; */
  /* background: #46a0e3; */
  background: rgba(70, 160, 227, 0.9);
  position: absolute;
  top: 100px;
  left: -20%;
  z-index: 1;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  padding: 0.5rem 2.9rem;
  box-sizing: border-box;
}
.one_nav li {
  list-style: none;
  padding: 1.5rem 0.2rem;
  /* padding-left: 1.6rem; */
  position: relative;
  color: #fff;
}
.one_nav li > a {
  font-size: 1rem;
  position: absolute;
  top: 50%;
  left: -1.2rem;
  transform: translateY(-50%);
}
.one_nav li > p:nth-child(1) {
  font-size: 2rem;
  margin-bottom: 5px;
  white-space: nowrap;
}
.one_nav li > p:nth-child(2) {
  font-weight: normal;
  letter-spacing: 3px;
  font-size: 1.6rem;
}
.one_nav li:hover {
  color: #f9cb4b;
  /* background: rgba(255, 255, 255, 0.5); */
  cursor: pointer;
}
.bot_nav {
  width: 100%;
  /* height: 426px; */
  height: 46vh;
  max-height: 500px;
  /* height: 42rem; */
  position: absolute;
  top: 98px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 999 !important;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.help_us_nav {
  height: 526px;
}
.bot_nav_left {
  width: 300px;
  height: 100%;
  padding: 20px 15px;
  box-sizing: border-box;
  background: #fff;
  color: #3581c6;
  display: none;
}
.bot_nav_left li {
  list-style: none;
  margin: 15px 0;
  font-size: 1.7rem;
  font-weight: 600;
}
.bot_nav_left li > span {
  font-size: 2.6rem;
  margin-right: 3px;
  vertical-align: middle;
}
.bot_nav_left .bot_nav_left_item {
  color: #3581c6;
}
.bot_nav_left .bot_nav_left_item:hover {
  color: #f9cb4b !important;
  cursor: pointer;
}
.bot_nav_right {
  /* width: calc(100% - 300px); */
  width: 100%;
  height: 100%;
  background: #3581c6;
  color: #ffffff;
}
.bot_nav_right ul {
  width: 98%;
  height: 100%;
  margin: 0 auto;
}
.bot_nav_right li {
  list-style: none;
  float: left;
  width: calc(25% - 3.6rem);
  height: calc(100% - 5rem);
  margin: 2.5rem 1.8rem;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-wrap;
  display: flex;
  align-content: center;
  flex-wrap: wrap;
}
.bot_nav_right li a {
  display: block;
  width: 100%;
  /* height: 268px; */
  /* height: 65%; */
  overflow: hidden;
}
.bot_nav_right li a:nth-child(1) {
  height: 50%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.bot_nav_right li a:nth-child(2) {
  height: 40%;
}
.bot_nav_right li img {
  /* width: auto;
  min-width: 100%; */
  width: 100%;
  /* height: 70%; */
  height: 100%;
  /* object-fit: cover; */
  transition: all 0.6s;
}
.bot_nav_right li img:hover {
  transform: scale(1.3);
}
.bot_nav_right li p {
  width: 100%;
  margin: 2rem 0;
  font-size: 1.8rem;
  font-weight: 600;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  margin-bottom: 10px;
}
.bot_nav_right li span {
  width: 100%;
  font-size: 1.6rem;
  display: block;
  margin-top: 5px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}
.bot_nav_right li:hover {
  color: #f9cb4b;
  cursor: pointer;
}
/* 微信下拉框 */

.weixin-dropdown {
  width: 120px;
  height: 120px;
  /* background-color: rgba(249, 203, 75, 0.8); */
  /* background-color: rgba(220, 220, 220, 0.9); */
  /* background-color: rgba(247, 248, 252, 0.9); */
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 2px;
  z-index: 99;
  display: none;
  cursor: pointer;
  animation: elseShow 0.5s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
}

.right-top-right-nav {
  position: relative;
}

.weixin-dropdown:hover {
  display: block;
}

@keyframes elseShow {
  0% {
    height: 50px;
  }

  100% {
    height: 120px;
  }
}

.weixin-dropdown > img {
  /* padding: 10px; */
  width: 100%;
  box-sizing: border-box;
  animation: elseShow 0.5s ease;
}

@keyframes elseShow {
  0% {
    height: 50px;
  }

  100% {
    height: 120px;
  }
}

.contact-linxifangshi-wx:hover .weixin-dropdown {
  display: block;
}

/* 移动端菜单 */
.app_header {
  height: 19rem;
}
.app_top_left_logo {
  width: 350px;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
  background: #ffffff;
  display: flex;
  align-items: center;
}
.app_top_left_logo > img {
  /* width: 120px; */
  width: auto;
  /* height: 150px; */
  height: 100%;
  /* padding: 1rem 2rem; */
  box-sizing: border-box;
}
.app_right_nav {
  width: 100%;
  height: 100%;
  position: relative;
}
.app_right_nav > span {
  font-size: 5rem;
  position: absolute;
  right: 2rem;
  top: 50%;
  transform: translateY(-50%);
  color: #3581c6;
  font-weight: 600;
}
.app_nav {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #3580c6cb;
  transition: all 0.5s;
}
.app_nav > span {
  position: absolute;
  left: 5rem;
  top: 5rem;
  font-size: 10rem;
  color: #ffffff;
}
.app_nav_right_nav {
  width: 95vw;
  height: 100vh;
  /* background: #46a0e3; */
  position: absolute;
  right: 0;
  top: 0;
  overflow-y: auto;
  transition: all 0.5s;
}
.app_nav_right_nav > ul {
  list-style: none;
  padding: 5rem 0;
  box-sizing: border-box;
}
.app_nav_right_nav > ul > li {
  color: #ffffff;
  position: relative;
  padding: 3.5rem 4.5rem;
  box-sizing: border-box;
}
.app_nav_right_nav > ul > li > a {
  position: relative;
  width: 100%;
}
.app_nav_right_nav > ul > li > a > .en_title {
  font-size: 5rem;
}
.app_nav_right_nav > ul > li > a > .reo {
  font-size: 4rem;
  margin-top: 1rem;
}
.app_nav_right_nav > ul > li > a > .youjt {
  position: absolute;
  right: 4rem;
  top: 50%;
  transform: translateY(-50%);
  font-size: 6rem;
  transition: all 0.5s;
}
.app_nav_right_nav .one_nav {
  position: relative;
  top: 3rem;
  left: 0;
  background: transparent;
  box-shadow: none;
  transition: all 0.5s;
}
.app_nav_right_nav .one_nav li {
  padding: 3rem 4rem;
  padding-right: 0;
  transition: all 1s ease;
}
.app_nav_right_nav .one_nav li > p:nth-child(1) {
  /* font-size: 4.2rem; */
  font-size: 4rem;
  margin-left: 1rem;
  white-space: pre-wrap;
  display: -webkit-box;
  /* 弹性盒子元素垂直排列 */
  -webkit-box-orient: vertical;
  /* 控制要显示的行数 */
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.app_nav_right_nav .one_nav li > p:nth-child(2) {
  font-size: 3.4rem;
  margin-top: 1rem;
  margin-left: 1rem;
}
.app_nav_right_nav .one_nav li > a {
  font-size: 6rem;
  left: -2rem;
  /* left: auto;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%); */
}
.app_nav_right_nav .one_nav li:hover {
  color: #ffffff;
}
.app_nav_right_nav .one_nav li > p:nth-child(2) {
  letter-spacing: 1px;
}
.app_nav_right_nav .three_nav li > p {
  white-space: pre-wrap;
  margin-bottom: 0;
}
.app_nav_right_nav #lianxi_nav li > a {
  font-size: 5rem;
}
.app_nav_right_nav #lianxi_nav li > p {
  margin-top: 0;
  margin-left: 0;
}
#toggle {
  display: none;
}

/**
  Hamburger
**/
.hamburger {
  position: absolute;
  right: 2vw;
  top: 50%;
  transform: translateY(-50%);
  z-index: 999;
}

.hamburger div {
  position: relative;
  width: 38px;
  height: 5px;
  border-radius: 3px;
  background-color: #3581c6;
  transition: all 0.3s ease-in-out;
}
.hamburger .meat,
.hamburger .bottom-bun {
  margin-top: 7px;
}
/**
Nav Styles
**/
.nav {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #3580c6e2;
  top: -100%;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  transform: scale(0);
  z-index: 888;
  font-weight: 500;
}
.nav-wrapper {
  position: relative;
  overflow: hidden;
  overflow-y: auto;
  height: 100%;
}
nav {
  text-align: left;
  margin-left: 25%;
}
nav a {
  position: relative;
  text-decoration: none;
  font-size: 2em;
  display: inline-block;
  /* margin-top: 1.25em; */
  transition: color 0.2s ease-in-out;
  letter-spacing: 1px;
}
/* nav a:before {
  content: "";
  height: 0;
  position: absolute;
  width: 0.25em;
  background-color: white;
  left: -0.5em;
  transition: all 0.2s ease-in-out;
} */
/* nav a:hover {
  color: white;
}
nav a:hover:before {
  height: 100%;
} */

/**
Animations
**/
#toggle:checked + .hamburger .top-bun {
  transform: rotate(-45deg);
  margin-top: 25px;
  background: #ffffff;
}
#toggle:checked + .hamburger .bottom-bun {
  opacity: 0;
  transform: rotate(45deg);
  background: #ffffff;
}
#toggle:checked + .hamburger .meat {
  transform: rotate(45deg);
  margin-top: -7px;
  background: #ffffff;
}

#toggle:checked + .hamburger + .nav {
  top: 0;
  transform: scale(1);
}
/* 顶部结束 */
.el-main {
  /* overflow: hidden !important; */
  margin-top: 180px;
  background-color: #ffffff;
  color: #333;
  padding: 0 !important;
}
.btnTop {
  max-width: 68px;
  max-height: 68px;
  width: 13rem;
  height: 13rem;
  background: #303b68;
  position: fixed;
  right: 2rem;
  z-index: 99;
  bottom: 5rem;
  background: url(../assets/img/top.png) no-repeat center;
  background-size: cover;
}
/* 底部 */
.el-footer {
  width: 100%;
  /* height: 377px !important; */
  padding: 0 !important;
}
.footer {
  width: 120rem;
  margin: 0 auto;
  position: relative;
  color: #ffffff;
}
.footer_top {
  width: 100%;
  /* height: calc(100% - 9rem); */
  padding: 2rem 0;
  background: #46a0e3;
}
.footer_top > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footer_top > div > div:nth-child(1) {
  /* color: #0166F2; */
  font-size: 1.8rem;
  overflow: hidden;
  /* font-weight: 600; */
  /* padding-top: 10px; */
  box-sizing: border-box;
}
.footer_top > div > div:nth-child(1) p {
  margin: 2rem 0;
}
.footer_top > div > div:nth-child(1) p span {
  font-size: 1.9rem;
  margin: 0;
  margin-right: 1.2rem;
}
.footer_top > div > div:nth-child(2) {
  width: 66rem;
  /* height: 227px; */
  padding: 2rem 1rem;
  background: #3581c6;
  /* position: absolute;
  right: 0;
  top: 2rem; */
  position: relative;
}
/* .footer_top > div > div:nth-child(2) ul {
  margin-top: 2rem;
} */
.footer_top > div > div:nth-child(2) li {
  list-style: none;
  font-size: 1.6rem;
  font-weight: 600;
  /* height: 4.2rem; */
  line-height: 200%;
}
.footer_top > div > div:nth-child(2) li span {
  text-transform: uppercase;
  margin: 0 2rem 0 3rem;
}
.footer_top > div > div:nth-child(2) li span:hover {
  text-decoration: underline;
  cursor: pointer;
}
.footer_top > div > div:nth-child(2) > div {
  width: 14rem;
  height: 14rem;
  position: absolute;
  right: 3rem;
  top: 10%;
  display: flex;
  flex-wrap: wrap;
}
.footer_top > div > div:nth-child(2) > div > span {
  font-size: 4.9rem;
  margin: 1rem;
  cursor: pointer;
}
.footer_bottom {
  width: 100%;
  height: 6rem;
  background: #3581c6;
  color: #ffffff;
  text-align: center;
  line-height: 6rem;
  font-size: 1.6rem;
}
/* max大的在上；min小的在上 */
/* @media (max-width: 1024px) {
  .top_right_bot > div:nth-child(1) > ul > li {
    margin-right: 5rem;
  }
  .footer_top > div {
    width: 150rem;
  }
  .footer_top > div > div:nth-child(2) {
    width: 80rem;
  }
} */
</style>
