import Vue from 'vue'
import VueRouter from 'vue-router';
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import './assets/css/common.css'
import axios from 'axios'
import qs from 'qs'
import '@/assets/font/iconfont.css'
import '@/assets/js/rem.js'
// import '@/assets/js/swiper.min.js'
// import '@/assets/css/swiper.min.css'


Vue.use(qs);
Vue.prototype.$axios = axios;

Vue.prototype.Api = Glob.baseUrl;

const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return routerPush.call(this, location).catch(error => error)
}

router.beforeEach((to, from, next) => {
    if (from.name != 'MedicalRecordGroup') {
        store.commit('clearToken')
    }
    if (to.meta.requireAuth) {
        next();
    } else {
        next();
    }
});

Vue.config.productionTip = false
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
